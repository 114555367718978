<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { DR_FETCH_TERMINALS } from '@/store/actions'
import { mapActions } from 'vuex'

export default {
  name: 'Index',
  mounted() {
    this.fetchTerminals(1)
  },
  methods: {
    ...mapActions({
      fetchTerminals: DR_FETCH_TERMINALS,
    }),
  },
}
</script>
